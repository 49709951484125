import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleCreateOrder = (
    passengers,
    email,
    firstName,
    lastName,
    extraSelected,
    delivery,
    flight,
    ip,
    referer,
    productTicketId,
    extraProductId,
    insuranceSelected,
    insuranceProductId,
) => {

    return new Promise((resolve, reject) => {
        let deliveryDate = null;
        let extra = [];
        if (extraSelected) {
            deliveryDate = formatDate(new Date(delivery));
            extra = [extraProductId];
        }
        if (insuranceSelected) {
            for (let i = 0; i < passengers.length; i++) {
                extra.push(insuranceProductId);
            }

        }

        flight = JSON.parse(JSON.stringify(flight));

        flight.departureDate = formatDate(new Date(flight.departureDate));
        flight.returnDate = formatDate(new Date(flight.returnDate));

        if (flight.route === "ROUND_TRIP") {
            flight.route = "round-trip"
        } else {
            flight.route = "one-way";
        }

        flight.segments = [flight.flight];
        delete flight.flight;
        if (flight.key) {
            delete flight.key;
        }

        console.log(flight);

        http.post(
            "public/orders",
            {
                passengers: passengers,
                email: email,
                first_name: firstName,
                last_name: lastName,
                delivery_date: deliveryDate,
                flight: flight,
                total_tickets: passengers.length,
                ip: ip,
                utm_source: referer,
                product_ticket_id: productTicketId,
                extras: extra,

            },
            (err, res) => {
                err
                    ? reject(err)
                    : resolve({
                        state: "success",
                        data: res.data
                    })
            }
        )
    });
}

function formatDate(date) {

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;

    return `${year}-${paddedMonth}-${paddedDay}`;
}

import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleFindProductBySku = (sku) => {

    return new Promise((resolve, reject) => {

        http.get(
            "public/products/ticket",
            {
                sku: sku
            },
            (err, res) => {

                if (err) {
                    reject(err);
                    return;
                }

                if (res.status > 299) {
                    reject(res.data.error);
                    return;
                }

                resolve({
                    statusCode: res.status,
                    data: res.data
                })
            }
        )
    });

}

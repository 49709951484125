import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleCreatePayment = (orderId, token, cardCountry, cardBrand, cardMasked, ip, cardHolder, email) => {

    return new Promise((resolve, reject) => {

        http.post(
            "public/transactions/payment",
            {
                order_id: orderId,
                card_token: token,
                card_country: cardCountry,
                card_brand: cardBrand,
                card_masked: cardMasked,
                ip: ip,
                card_holder: cardHolder,
                email: email,
            },
            (err, res) => {

                if (err) {
                    reject(err);
                    return;
                }

                if (res.status > 299) {
                    reject(res.data.error);
                    return;
                }

                resolve({
                    statusCode: res.status,
                    data: res.data
                })
            }
        )
    });

}

import React, {useEffect, useRef, useState} from "react";
import CheckoutFormArea from "./checkout.style";
import {handleFindProductBySku} from "../../../usecases/product/findProductBySku";
import {navigate} from "gatsby";
import {handleFindExtraProductBySku} from "../../../usecases/product/findExtraProductBySku";
import Container from "../../../common/components/UI/Container";
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPlane} from "@fortawesome/free-solid-svg-icons";
import {FaPlaneDeparture} from 'react-icons/fa';
import {faClose} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement, IdealBankElement
} from "@stripe/react-stripe-js"
import {handleCreateOrder} from "../../../usecases/order/createOrder";
import {handleCreatePayment} from "../../../usecases/transaction/createPayment";
import {handleCreatePaymentIntent} from "../../../usecases/transaction/createPaymentIntent";
import {logger} from "../../../infrastructure/logger";
import {handleConfirmStripePayment} from "../../../usecases/transaction/confirmStripePayment";
import * as moment from "moment"

const CustomDatePickerInput = React.forwardRef(({value, onClick}, ref) => (
    <button className="input react-datepicker-ignore-onclickoutside" onClick={onClick} ref={ref}>
        <FontAwesomeIcon icon={faCalendarAlt}/>
        {value}
    </button>
));

const CheckoutForm = (props) => {

    const stripe = useStripe()
    const elements = useElements()

    const [ticketProduct, setTicketProduct] = useState(null);
    const [extraProduct, setExtraProduct] = useState(null);
    const [insuranceProduct, setInsuranceProduct] = useState(null);
    const [cookies, setCookie] = useCookies(['order']);
    const [order, setOrder] = useState(null);
    const [extraSelected, setExtraSelected] = useState(false);
    const [insuranceSelected, setInsuranceSelected] = useState(true);
    const [passengers, setPassengers] = useState([]);

    const [extraAmount, setExtraAmount] = useState(0);
    const [insuranceAmount, setInsuranceAmount] = useState(0);
    const [scheduledEnabled, setScheduledEnabled] = useState(true);

    const [totalAmount, setTotalAmount] = useState(0);
    let startDateRa = moment(new Date());
    startDateRa.add(2, 'days');
    const [startDate, setStartDate] = useState(startDateRa.toDate());
    const [scheduledDate, setScheduledDate] = useState(startDateRa.toDate());
    const [email, setEmail] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerLastName, setCustomerLastName] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formError, setFormError] = useState("");

    // validations
    const [nameInvalid, setNameInvalid] = useState(false);
    const [lastNameInvalid, setLastNameInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [isExtraError, setIsExtraError] = useState(false);
    const [extraError, setExtraError] = useState("Reception date cannot be later than the flight date");

    const getStandardTicketProduct = async (orderInline) => {
        try {
            const res = await handleFindProductBySku("TK1");
            setTicketProduct(res.data);
            await getInsuranceProduct(orderInline, res.data);
        } catch (error) {
            await navigate("/", {});
        }
    }

    const getRoundTripTicketProduct = async (orderInline) => {
        try {
            const res = await handleFindProductBySku("TK2");
            setTicketProduct(res.data);
            await getInsuranceProduct(orderInline, res.data);
        } catch (error) {
            await navigate("/", {});
        }
    }

    const getScheduledProduct = async () => {
        try {
            const res = await handleFindExtraProductBySku("EX1");
            setExtraProduct(res.data);
        } catch (error) {
            await navigate("/", {});
        }
    }

    const getInsuranceProduct = async (orderp, ticket) => {
        try {
            const res = await handleFindExtraProductBySku("EX2");
            setInsuranceProduct(res.data);
            console.log(orderp.passengers, ticket.amount, res.data.amount)
            setTotalAmount(orderp.passengers * ticket.amount + (orderp.passengers * res.data.amount));
        } catch (error) {
            await navigate("/", {});
        }
    }

    const calculateTotalAmount = (passengersInline, ticketAmount) => {
        if (order && ticketProduct && insuranceProduct) {
            let insurance = 0;
            if (insuranceSelected) {
                insurance = order.passengers * insuranceProduct.amount;
            }
            setTotalAmount(order.passengers * ticketProduct.amount + extraAmount + insurance);
        }
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [extraSelected, insuranceSelected, extraAmount, insuranceAmount]);

    const calculateTicketsAmount = () => {
        return order.passengers * ticketProduct.amount;
    }

    const calculateOriginStops = () => {
        if (order.flight.origin.length === 1) {
            return "Direct"
        } else {
            return order.flight.origin.length - 1 + " stops";
        }
    }
    const getDepartureTime = (item) => {
        const date = new Date(item.departureDate)
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Pad the minutes and hours with leading zeros if necessary
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }
    const getRoute = (route) => {
        if (route === "ROUND_TRIP") {
            return "Round Trip"
        }
        return "One Way"
    }
    const getArrivalTime = (item) => {
        const date = new Date(item.arrivalDate)
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Pad the minutes and hours with leading zeros if necessary
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }

    const handleScheduledProduct = (event) => {
        const checked = event.target.checked;
        setExtraSelected(checked);
        setExtraAmount(checked ? extraProduct.amount : 0);
    }

    const handleInsuranceProduct = (event) => {
        const checked = event.target.checked;
        setInsuranceSelected(checked);
        setInsuranceAmount(checked ? order.passengers * insuranceProduct.amount : 0);
    }


    const setPassengerName = (index, value) => {
        const newPassengers = passengers.map((item, idx) => {
            if (index === idx) {
                return {...item, name: value};
            }
            return item;
        });
        setPassengers(newPassengers);
    };
    const setPassengerTitle = (index, event) => {
        const value = event.target.value;
        const newPassengers = passengers.map((item, idx) => {
            if (index === idx) {
                return {...item, title: value};
            }
            return item;
        });
        setPassengers(newPassengers);
    }

    const removePassenger = (index) => {
        if (index === 0) {
            return;
        }
        const newPassengers = passengers.filter((_, idx) => idx !== index);
        setPassengers(newPassengers);
        order.passengers = order.passengers - 1;
        calculateTotalAmount(order.passengers, ticketProduct.amount);
    }

    const addPassenger = () => {
        const newPassenger = {title: "Mr", name: "", invalid: false};
        setPassengers([...passengers, newPassenger]);
        order.passengers = order.passengers + 1;
        calculateTotalAmount(order.passengers, ticketProduct.amount);
    };

    const handleOrder = async () => {

        setIsError(false);
        setNameInvalid(false);
        setLastNameInvalid(false);
        setEmailInvalid(false);
        setIsSubmitting(true);

        if (!stripe || !elements) {
            return;
        }

        let passengersInvalid = false;

        let newPassengers = passengers.map((item, idx) => {
            if (item.name === "") {
                passengersInvalid = true;
                return {...item, invalid: true};
            } else {
                return {...item, invalid: false};
            }
        });

        setPassengers(newPassengers);

        if (passengersInvalid) {
            document.getElementById("passengers").scrollIntoView();
            setIsSubmitting(false);
            return;
        }


        if (customerName === "") {
            setNameInvalid(true);
            document.getElementById("account").scrollIntoView();
            setIsSubmitting(false);
            return;
        }

        if (customerLastName === "") {
            setLastNameInvalid(true);
            document.getElementById("account").scrollIntoView();
            setIsSubmitting(false);
            return;
        }

        if (email === "") {
            setEmailInvalid(true);
            document.getElementById("account").scrollIntoView();
            setIsSubmitting(false);
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);
        const res = await stripe.createToken(cardElement);

        if (res.error) {
            setIsSubmitting(false);
            setIsError(true);
            if (res.error.message) {
                setFormError(res.error.message);
            } else {
                setFormError("an error has occurred, try later");
            }
            return;
        }

        await createOrder(res.token);
    }

    const createOrder = async (paymentMethod) => {
        let res;
        try {
            res = await handleCreateOrder(
                passengers,
                email,
                customerName,
                customerLastName,
                extraSelected,
                scheduledDate,
                order,
                paymentMethod.client_ip,
                order.referer,
                ticketProduct.id,
                extraProduct.id,
                insuranceSelected,
                insuranceProduct.id
            );
        } catch (error) {
            setIsError(true);
            setFormError("an error has occurred, try later");
            setIsSubmitting(false);
            return;
        }
        await createTransaction(res.data.id, res.data.ref, paymentMethod);
    }

    const createTransaction = async (orderId, orderRef, paymentMethod) => {
        let res;
        let intentResponse;
        try {
            res = await handleCreatePayment(
                orderId,
                paymentMethod.id,
                paymentMethod.card.country,
                paymentMethod.card.brand,
                paymentMethod.card.last4,
                paymentMethod.client_ip,
                customerName + " " + customerLastName,
                email
            );
            logger.info(res);
            intentResponse = await handleCreatePaymentIntent(res.data.id);
            logger.info(intentResponse);
        } catch (error) {
            setIsError(true);
            setFormError("an error has occurred, try later");
            setIsSubmitting(false);
            return;
        }


        const cardElement = elements.getElement(CardNumberElement);

        const stripeResponse = await stripe.confirmCardPayment(
            intentResponse.data.client_secret,
            {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: customerName + " " + customerLastName,
                        email: email
                    }
                }
            }
        );

        if (stripeResponse.error) {
            setIsError(true);
            setFormError(stripeResponse.error.message);
            setIsSubmitting(false);
            return;
        }

        try {
            await handleConfirmStripePayment(res.data.id);
            navigate('/thank-you?ref=' + orderRef);
        } catch (error) {
            setIsError(true);
            setFormError("an error has occurred, try later");
            setIsSubmitting(false);
        }
    }

    const selectDelayedDate = (date) => {
        setIsExtraError(false);
        const departureDate = order.departureDate;
        if (date > departureDate) {
            setIsExtraError(true);
            return;
        }
        setScheduledDate(date);
    }

    useEffect(async () => {
        if (props.state == null && (!cookies || !cookies.order)) {
            await navigate("/", {});
        }
        let orderInline = null;
        if (props.state == null) {
            setOrder(cookies.order);
            orderInline = cookies.order;
        } else {
            setOrder(props.state);
            orderInline = props.state;
        }
        const today = moment();
        today.add(1, 'days');

        let scheduledDate = moment(orderInline.departureDate);

        if (today < scheduledDate) {
            setScheduledDate(today.toDate());
            setStartDate(today.toDate());
        } else {
            setScheduledEnabled(false);
        }

        const loadPassengers = () => {
            const initialPassengers = [];
            for (let i = 0; i < orderInline.passengers; i++) {
                initialPassengers.push({title: "Mr", name: "", invalid: false});
            }

            setPassengers(initialPassengers);
        };
        loadPassengers();

        if (orderInline.route === "ROUND_TRIP") {
            await getRoundTripTicketProduct(orderInline);
        } else {
            await getStandardTicketProduct(orderInline);
        }
        await getScheduledProduct();

        const dragDiv = document.getElementById('checkout-mobile-d');
        let startY = 0;
        let endY = 0;
        if (dragDiv) {
            dragDiv.addEventListener('touchstart', function (e) {
                startY = e.touches[0].clientY;
            });
            dragDiv.addEventListener('touchmove', function (e) {
                endY = e.touches[0].clientY;
            });
            dragDiv.addEventListener('touchend', function () {
                if (startY > endY) {
                    dragDiv.classList.add('ch-drag-up');
                    dragDiv.classList.remove('ch-drag-down');
                } else if (startY < endY) {
                    dragDiv.classList.add('ch-drag-down');
                    dragDiv.classList.remove('ch-drag-up');
                }
            });
        }

        setExtraAmount(0);

    }, []);

    return (
        <CheckoutFormArea id="checkout_form_section">
            <Container>
                <section className="flight-section">
                    <h1>Flight Information</h1>
                    <div className="breadcrumb">
                        <div className="breadcrumb-item">
                            <span className="active">1</span>
                            <h5 style={{color: "#2678DD"}}>Flight Information</h5>
                        </div>
                        <div className="breadcrumb-item">
                            <span>2</span>
                            <h5>Payment Methods</h5>
                        </div>
                        <div className="breadcrumb-item">
                            <span>3</span>
                            <h5>Finish Your Ticket</h5>
                        </div>
                    </div>
                    {ticketProduct && extraProduct && order && insuranceProduct && (
                        <div className="checkout-content clearfix">
                            <div className="information clearfix">


                                <div className="tab" style={{marginTop: "15px"}} id="passengers">
                                    <div className="tab-head">
                                        <h6> Traveller(s)</h6>
                                    </div>
                                    <div className="tab-middle noFlex" style={{width: "100%"}}>
                                        {passengers.map((item, index) => (
                                            <div className="form-outline" key={"pass" + index} style={{height: "43px"}}>
                                                <select
                                                    className="input i-title"
                                                    style={{width: "10%"}}
                                                    onChange={val => setPassengerTitle(index, val)}
                                                >
                                                    <option value="Mr">Mr</option>
                                                    <option value="Ms">Ms</option>
                                                </select>
                                                <input
                                                    type="text"
                                                    value={item.name}
                                                    className="input i-traveller"
                                                    placeholder="Enter Full Name"
                                                    onChange={val => setPassengerName(index, val.target.value)}
                                                    style={{width: "75%", border: item.invalid ? '1px solid red' : ''}}
                                                />
                                                {index !== 0 && (
                                                    <div className="closeDiv" onClick={e => removePassenger(index)}>
                                                        <FontAwesomeIcon icon={faClose}
                                                                         style={{
                                                                             display: "inline-block",
                                                                             fontSize: "18px",
                                                                             cursor: "pointer"
                                                                         }}/>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <button className="button button-primary" style={{marginTop: "25px"}}
                                            onClick={addPassenger}>Add Traveller
                                    </button>
                                </div>
                                <img src="/img/line.png" width="100%" style={{marginTop: "40px"}}/>
                                <div className="tab" style={{marginTop: "15px"}} id="account">
                                    <div className="tab-head">
                                        <h6> Contact Details</h6>
                                    </div>
                                    <div className="tab-middle noFlex" style={{width: "100%"}}>
                                        <div className="form-outline ">
                                            <label style={{display: "block", marginBottom: "5px"}}>
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                value={customerName}
                                                className="input i-detail"
                                                placeholder="Enter your name"
                                                onChange={val => setCustomerName(val.target.value)}
                                                style={{width: "65%", border: nameInvalid ? '1px solid red' : ''}}
                                            />
                                        </div>
                                        <div className="form-outline ">
                                            <label style={{display: "block", marginBottom: "5px"}}>
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                value={customerLastName}
                                                className="input i-detail"
                                                placeholder="Enter your last name"
                                                onChange={val => setCustomerLastName(val.target.value)}
                                                style={{width: "65%", border: lastNameInvalid ? '1px solid red' : ''}}
                                            />
                                        </div>
                                        <div className="form-outline">
                                            <label style={{display: "block", marginBottom: "5px"}}>
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                value={email}
                                                className="input i-detail"
                                                placeholder="Enter an email"
                                                onChange={val => setEmail(val.target.value)}
                                                style={{width: "65%", border: emailInvalid ? '1px solid red' : ''}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <img src="/img/line.png" width="100%" style={{marginTop: "40px"}}/>

                                <div className="tab" style={{marginTop: "15px"}} id="insurance">
                                    <div className="tab-head">
                                        <h6>With us you are covered</h6>
                                    </div>
                                    <div className="tab-middle noFlex" style={{width: "100%"}}>
                                        <div className="insurance-checkbox">
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <input
                                                    type="checkbox"
                                                    checked={insuranceSelected}
                                                    onChange={handleInsuranceProduct}
                                                    name="insurance-product"
                                                    className="custom-checkbox"
                                                />
                                                <label className="later"
                                                       style={{
                                                           fontWeight: "500",
                                                           color: "black",
                                                           fontSize: "18px",
                                                           marginLeft: "5px"
                                                       }}>
                                                    Travel Protection Plan ${insuranceProduct.amount * order.passengers}
                                                </label>
                                            </div>
                                            <div>
                                                <ul style={{marginLeft: "30px", marginTop: "20px"}}>
                                                    <li style={{
                                                        width: "100%",
                                                        marginBottom: "10px",
                                                        listStyleType: "square"
                                                    }}>
                                                        A 100% reimbursement of the total tickets price
                                                    </li>
                                                    <li style={{width: "100%", listStyleType: "square"}}>
                                                        Amendments of the personal information, printed on the Tickets
                                                        and resending them if required
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <img src="/img/line.png" width="100%" style={{marginTop: "40px"}}/>

                                <div className="tab tab-payment" style={{marginTop: "25px", marginBottom: "30px"}}>
                                    <div className="tab-head">
                                        <h6> Payment</h6>
                                    </div>
                                    <div className="tab-middle noFlex" style={{width: "100%"}}>
                                        <div style={{marginBottom: "15px"}}>
                                            <label style={{fontSize: "14px"}}>Card Number</label>
                                            <CardNumberElement
                                                className="input"
                                                options={{
                                                    style: {
                                                        base: {
                                                            fontSize: "16px",
                                                            color: "rgba(100, 116, 139, 1)",
                                                            "::placeholder": {
                                                                color: "rgba(100, 116, 139, 1)",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}/>
                                        </div>
                                        <div className="half">
                                            <label style={{fontSize: "14px"}}>Expiration</label>
                                            <CardExpiryElement
                                                className="input"

                                                options={{
                                                    style: {
                                                        base: {
                                                            fontSize: "16px",
                                                            color: "#424770",
                                                            "::placeholder": {
                                                                color: "#aab7c4",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="half ml2">
                                            <label style={{fontSize: "14px"}}>CVC</label>
                                            <CardCvcElement
                                                className="input"
                                                options={{
                                                    style: {
                                                        base: {
                                                            fontSize: "16px",
                                                            color: "#424770",
                                                            "::placeholder": {
                                                                color: "#aab7c4",
                                                            },
                                                        },
                                                        invalid: {
                                                            color: "#9e2146",
                                                        },
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <img src={`/img/payment.png`} width="250px"/>
                                        </div>
                                        <small style={{color: 'gray', fontSize: '10px', marginBottom: '10px'}}>
                                            *CVV number is a 3 digit numeric code behind the card
                                        </small>
                                    </div>

                                    <img src="/img/line.png" width="100%" style={{marginTop: "40px"}}/>
                                    {scheduledEnabled && (
                                        <div className="tab" style={{marginTop: "25px"}}>
                                            <div className="extra-checkbox"
                                                 style={{display: "flex", alignItems: "center"}}>
                                                <input
                                                    type="checkbox"
                                                    checked={extraSelected}
                                                    onChange={handleScheduledProduct}
                                                    name="extra-product"
                                                    className="custom-checkbox"
                                                />
                                                <label className="later"
                                                       style={{fontWeight: "500", color: "black", marginTop: "10px"}}>I
                                                    want
                                                    to receive my ticket
                                                    later(+${extraProduct.amount})</label>
                                            </div>
                                            {extraSelected && (
                                                <div className="input-container" style={{marginTop: "15px"}}>
                                                    <label className="inputLabel" htmlFor="startDate">Reception
                                                        Date*</label>
                                                    <DatePicker
                                                        selected={scheduledDate}
                                                        onChange={date => selectDelayedDate(date)}
                                                        minDate={startDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="input"
                                                        customInput={<CustomDatePickerInput/>}
                                                    />
                                                </div>

                                            )}
                                            {isExtraError && (
                                                <div className="alert alert-warn">{extraError}</div>
                                            )}
                                        </div>
                                    )}

                                    <div style={{marginTop: "25px", float: "left"}} id="checkout-btn"
                                         className="checkout-btn-pc">
                                        {!isSubmitting && (
                                            <button className="button button-payment" onClick={handleOrder}> Complete
                                                your
                                                Booking
                                            </button>
                                        )}
                                        {isSubmitting && (
                                            <img src={`/img/Spinner-1s-200px.gif`} width="50px"/>
                                        )}

                                        {isError && (
                                            <div className="alert alert-error">{formError}</div>
                                        )}
                                    </div>
                                </div>

                            </div>

                            {order.flight.origin[0] && ticketProduct && extraProduct && order && insuranceProduct && (

                                <div className="boarding boarding-pc">
                                    <div className="boarding-row">
                                        <div><img src={order.flight.origin[0].logo}/></div>
                                        <div><span>Total</span> <span>$ {totalAmount}</span></div>
                                    </div>
                                    <img src="/img/line.png" width="100%"
                                         style={{marginBottom: "20px", marginTop: "10px"}}/>
                                    <div className="boarding-times">
                                        <div>
                                            <span>Departure</span><span>{getDepartureTime(order.flight.origin[0])}</span>
                                        </div>
                                        <div className="routev">
                                            {getRoute(order.route)}
                                        </div>
                                        <div>
                                            <span>Arrival</span><span>{getArrivalTime(order.flight.origin[order.flight.origin.length - 1])}</span>
                                        </div>
                                    </div>
                                    <div className="boarding-destinations">
                                        <div>{order.flight.origin[0].from}</div>
                                        <div><FaPlaneDeparture
                                            style={{
                                                display: "inline-block",
                                                color: "#2678dd",
                                                fontSize: "22px"
                                            }}/></div>
                                        <div>{order.flight.origin[order.flight.origin.length - 1].to}</div>
                                    </div>
                                    <img src="/img/line.png" width="100%"
                                         style={{marginBottom: "20px", marginTop: "20px"}}/>
                                    <div className="boarding-date">
                                        <div>
                                            <span>Date</span><span>{order.flight.origin[0].date}</span>
                                        </div>
                                    </div>
                                </div>

                            )}

                        </div>
                    )}

                </section>
            </Container>
            {ticketProduct && extraProduct && order && order.flight.origin[0] && insuranceProduct && (
                <div className="checkout-mobile ch-drag-down" id="checkout-mobile-d">
                    <div style={{textAlign: "center"}}>
                        <img src="/img/line3.png" width="10%"
                             style={{marginTop: "10px", textAlign: "center", height: "5px"}}/>
                    </div>
                    <div className="boarding-row" style={{marginTop: "15px"}}>
                        <div><img src={order.flight.origin[0].logo}/></div>
                        <div><span>Total</span> <span>$ {totalAmount}</span></div>
                    </div>
                    <div className="boarding boarding-mobile">
                        <img src="/img/line.png" width="100%"
                             style={{marginBottom: "20px", marginTop: "0px"}}/>
                        <div className="boarding-times">
                            <div>
                                <span>Departure</span><span>{getDepartureTime(order.flight.origin[0])}</span>
                            </div>
                            <div className="routev">
                                {getRoute(order.route)}
                            </div>
                            <div>
                                <span>Arrival</span><span>{getArrivalTime(order.flight.origin[order.flight.origin.length - 1])}</span>
                            </div>
                        </div>
                        <div className="boarding-destinations">
                            <div>{order.flight.origin[0].from}</div>
                            <div><FaPlaneDeparture
                                style={{
                                    display: "inline-block",
                                    color: "#2678dd",
                                    fontSize: "22px"
                                }}/></div>
                            <div>{order.flight.origin[order.flight.origin.length - 1].to}</div>
                        </div>
                        <img src="/img/line.png" width="100%" style={{marginBottom: "20px", marginTop: "20px"}}/>
                        <div className="boarding-date">
                            <div>
                                <span>Date</span><span>{order.flight.origin[0].date}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{float: "left"}} id="checkout-btn"
                         className="checkout-btn-mobile">
                        {!isSubmitting && (
                            <button className="button button-payment" onClick={handleOrder}> Complete
                                your
                                Booking
                            </button>
                        )}
                        {isSubmitting && (
                            <img src={`/img/Spinner-1s-200px.gif`} width="50px"/>
                        )}

                        {isError && (
                            <div className="alert alert-error">{formError}</div>
                        )}
                    </div>
                </div>

            )}
        </CheckoutFormArea>
    )
}

export default CheckoutForm;

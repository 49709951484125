import styled from 'styled-components';

const CheckoutFormArea = styled.section`
    margin-top: 30px;

    .clearfix:after {
        content: "";
        clear: both;
        display: table;
    }

    .alert {
        width: 100%;
        margin-top: 30px;
        padding: 10px;
        border-radius: 5px;

    }

    .alert-error {
        background-color: #f8819b;
        border: 1px solid red;
        color: black;
    }

    .alert-info {
        background-color: #cccc5a;
        border: 1px solid #c5c530;
        color: black;
    }

    .alert-warn {
        background-color: #e3c138;
        border: 1px solid #e3c138;
        color: black;
    }

    .checkout-content {
        margin-top: 20px;
    }

    .flight-section h1 {
        font-size: 36px;
        display: inline;
        letter-spacing: -1px;
    }

    .information {
        float: left;
        width: 60%;
        margin-right: 3%;
    }

    .flight {
        float: left;
        width: 37%;
    }

    .boarding {
        float: left;
        width: 37%;
    }

    .tab {
        border-radius: 7px;
        width: 100%;
        padding: 15px;
        padding-left: 0px;
        float: left;
        background: white;
    }

    .tab-head h6 {
        font-weight: bold;
        font-size: 27px;
    }

    .tab-head, .tab-middle, .tab-bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .tab-middle, .tab-bottom {
        margin-top: 15px;
    }

    .tab-middle p {
        font-size: 16px;
    }

    .tab-bottom p {
        font-size: 20px;
        margin: 0;
        padding: 0;
    }


    .boarding {
        box-shadow: 0px 1px 4px 0px #0B0A441F;
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        border-radius: 5px;
        margin-top: 50px;

    }

    .boarding-row {
        flex: 1;
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .boarding-row > div {
        flex: 1;
        align-items: center;
    }

    .boarding-row > div > img {
        max-width: 80px;
    }

    .boarding-row > div > span {
        text-align: right;
        display: block;
    }

    .boarding-row > div > span:last-child {
        font-weight: bold;
        font-size: 25px;
        color: #2678DD;

    }

    .boarding-times {
        display: flex;
        width: 100%;
    }

    .boarding-times > div {
        flex: 1;
    }

    .boarding-times > div:last-child {
        text-align: right;
    }

    .boarding-times > div > span {
        display: block;
    }

    .boarding-times > div > span:first-child {
        color: #64748B;
        font-size: 14px;

    }

    .boarding-times > div > span:last-child {
        font-weight: 500;
        font-size: 20px;
    }

    .boarding-destinations {
        display: flex;
        align-items: center;
    }

    .boarding-destinations > div {
        flex: 1;
        margin-top: 20px;
        align-items: center;
    }

    .boarding-destinations > div:last-child {
        text-align: right;
    }
    .boarding-destinations > div:nth-child(1), .boarding-destinations > div:nth-child(3){
        font-size: 36px;
        font-weight: bold;
        color: #2678DD;
    }
    .boarding-destinations > div:nth-child(2) {
        text-align: center;
    }

    .boarding-date span{
        display: block;
    }
    .boarding-date span:first-child{
        color: #64748B;
        font-size: 14px;
    }
    .boarding-date span:last-child{
        color: black;
        font-size: 16px;
        font-weight: bold;
    }
    
    .boarding-mobile{
        display: none;
        width: 100%;
        box-shadow: 0px 0px 0px 0px #0B0A441F;
        margin-top: 0px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .checkout-btn-mobile{
        display: none;
        width: 100%;
    }
    .checkout-mobile{
        display: none;
    }
    .ch-drag-down > .boarding-mobile{
        display: none;
    }
    .ch-drag-up > .boarding-mobile{
        display: block;
    }
    .routev{
        text-align: center;
        margin-top: 14px;
        color: #64748B;
        text-transform: capitalize;
    }
    }
    @media (max-width: 960px) {
        .checkout-mobile{
            display: block;
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 999999;
            background: white;
            box-shadow: 0px 1px 4px 0px #0B0A441F;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
            border-radius: 10px;
        }
        .boarding-pc{
            display: none;
        }
        .boarding-mobile{
            display: block;
        }
        .checkout-btn-pc{
            display: none !important;
        }
        .checkout-btn-mobile{
            display: block;
        }
        .button-payment{
            width: 100%;
        }
    }
    .vy-journey {
        position: relative;
        display: table;
        width: 100%;
    }

    .vy-journey_info--bottom {
        color: #666;
        bottom: 0;
    }

    .vy-journey_origin {
        text-align: left;
    }

    .vy-journey_origin:before {
        left: 0;
    }

    .vy-journey_hour {
        font-size: 20px;
        color: #000;
        font-weight: 500;
        display: block;
        width: 100%;
    }

    .vy-journey_origin .vy-journey_content-point {
        padding-left: 20px;
        padding-right: 5px;
        padding-top: 10px;
    }

    .vy-journey_content-point {
        background: #fff;
        position: relative;
        display: inline-block;
    }

    .point {
        width: 18px;
        height: 18px;
        position: relative;
        display: inline-block;
        border: 1px solid #999;
        border-radius: 50%;
    }

    .vy-journey .point:after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #666;
        display: inline-block;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        position: absolute;
        -webkit-transform: translateY(-50%) translateX(-50%);
        -moz-transform: translateY(-50%) translateX(-50%);
        transform: translateY(-50%) translate(-50%);
    }

    .vy-journey_country {
        margin-top: 5px;
        color: #666;
        font-size: 1rem;
        display: block;
        position: relative;
    }

    .vy-journey_info--bottom {
        z-index: 1;
        width: 100%;
        font-size: .75rem;
        color: #666;
        text-align: center;
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        transform: translate(-50%);
    }

    .vy-journey-iata {
        width: 55px;
        text-align: center;
        display: inline-block;
        color: #333;
        font-weight: bold;
    }

    .vy-journey_destination .vy-journey_content-point {
        padding-right: 20px;
        padding-left: 5px;
        padding-top: 10px;
    }

    .vy-journey_icon {
        width: 1%;
        padding: 1.82em 1em 1em;
        display: table-cell;
        vertical-align: middle;
        position: relative;
        text-align: center;
        background: #fff;
    }

    .vy-journey_icon [class*=vy-icon] {
        font-size: 19px;
        color: #666;
    }

    .vy-icon-plane-right:before {

    }

    .vy-journey_destination {
        text-align: right;
        position: relative;
    }

    .vy-journey_origin:before, .vy-journey_destination:before {
        content: "";
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        top: -webkit-calc(50% + 2px);
        top: -moz-calc(50% + 2px);
        top: calc(50% + 2px);
        background-color: #ddd;
    }

    .trip-selector_head {
        margin-bottom: 5px;
    }

    .list-airlines {
        margin-bottom: 0;
        display: inline-block;
    }

    .list-airlines img {
        max-width: 30px;
    }

    .flight-code {
        font-family: SourceSansPro, Arial, sans-serif;
        font-size: .75rem;
        vertical-align: middle;
        line-height: 1rem;
        margin-right: 0.25rem;
        margin-left: 10px;
        top: -8px;
        position: relative;
        text-align: right;
    }

    .flight-date {

        font-size: 14px;
        vertical-align: middle;
        line-height: 1rem;
        margin-right: 0.25rem;
        margin-left: 10px;
        top: -7px;
        position: relative;
    }

    .trip-selector_head--airlines {
        float: left;
        padding-left: 0;
        padding-right: 0;
        width: 66.6666666667%;
        clear: none;
        text-align: left;
    }

    .trip-selector_head--duration {
        float: left;
        padding-left: 0;
        padding-right: 0;
        width: 33.3333333333%;
        clear: none;
        text-align: right;
        line-height: 35px;
    }

    .duration {
        font-family: SourceSansPro, Arial, sans-serif;
        font-size: .75rem;
        color: #0069de;
        font-weight: 400;
    }

    .fflight {
        margin-bottom: 15px;
    }

    .origin .fflight:last-child {
        margin-bottom: 0px;
        border-top: 1px solid #ECECEC;
        padding-top: 15px;
    }

    .return .fflight:last-child {
        margin-bottom: 0px;
        border-top: 1px solid #ECECEC;
        padding-top: 15px;
    }

    .origin .fflight:first-child, .return .fflight:first-child {
        border-top: 0 !important;
        padding-top: 0 !important;
    }

    .return {
        margin-top: 20px;
    }

    .custom-checkbox input:checked ~ label::before {
        background-color: #0d6efd; /* Color de fondo cuando está seleccionado */
    }

    .custom-checkbox {
        width: 2em; /* Aumenta el ancho del checkbox */
        height: 2em; /* Aumenta la altura del checkbox */
        cursor: pointer;
        border: 1px solid #d2c5c5;
    }

    .custom-checkbox label::before {
        border-radius: 50%;
        border: 0.1em solid #adb5bd;
    }

    .custom-checkbox label::before {
        border-radius: 50%; /* Hace que el checkbox sea redondo */
        height: 1.5em;
        width: 1.5em;
        pointer-events: none; /* Evita que el pseudo-elemento interfiera con el click */
        border: 0.1em solid #adb5bd; /* Establece el color del borde */
    }

    .custom-checkbox .label::after {
        top: calc(0.75em - 0.5em); /* Centra la marca de verificación verticalmente */
        left: calc(0.75em - 0.5em); /* Centra la marca de verificación horizontalmente */
        width: 0.5em;
        height: 1em;
    }

    .extra-checkbox label {
        position: relative;
        top: -2px;
        margin-left: 10px;
        font-size: 15px;
    }

    .form-outline {
        position: relative;
        width: 100%;
        margin-top: 10px;
    }

    .form-outline label {
        color: rgba(100, 116, 139, 1);
    }

    .tab-payment label {
        color: rgba(100, 116, 139, 1) !important;
        font-size: 16px !important;
        margin-bottom: 5px;
    }

    .tab-payment label.later {
        color: black !important;
    }

    .form-outline svg {
        position: absolute;
        top: 50%;
        transform: translateY(50%);
        pointer-events: none;
    }

    .half {
        width: 50%;
        float: left;
        margin-bottom: 10px;
    }

    .input-container .inputLabel {
        float: left;
        width: 100%;
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
        border-radius: 10px;
        color: rgba(100, 116, 139, 1);
    }

    .input {
        padding: 10px;
        margin-right: 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 10px;
        border: 1px solid rgba(226, 232, 240, 1);
        font-family: 'DM Sans', sans-serif;
        width: 100%;
        text-align: left;
        background: white;
        font-size: 16px;
        cursor: text;
        color: rgba(100, 116, 139, 1);
    }

    .input svg {
        margin-right: 10px;
    }

    .input:focus {
        border: 1px solid rgb(38, 120, 221);
    }

    .react-datepicker-wrapper {
        width: 70%;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        width: 100%; /* Ajusta el ancho del input si es necesario */
        font-size: 16px; /* Ajusta el tamaño de la fuente del input */
    }

    .react-datepicker__tab-loop {
        position: absolute;
        top: 0;
        left: 0;
    }

    .react-datepicker-wrapper {
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker {
        font-family: 'DM Sans', sans-serif; /* Cambia la fuente si es necesario */
        border: 1px solid #ccc;
        box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
        font-size: 16px; /* Aumenta el tamaño de la fuente dentro del datepicker */
        width: 350px; /* Aumenta el ancho del datepicker */
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 40px; /* Aumenta el tamaño de las celdas del calendario */
        height: 40px; /* Aumenta el tamaño de las celdas del calendario */
        line-height: 40px; /* Centra el texto verticalmente */
    }

    .react-datepicker__header {
        /*background-color: rgb(38, 120, 221);*/
        background-color: white;
        color: black;
    }

    .react-datepicker__current-month {
        font-weight: bold;
        color: black;
    }

    .react-datepicker__day:hover {
        background: #6989b0;
        color: white;
    }

    .react-datepicker__day, .react-datepicker__time-name {
        color: #333;
    }

    .react-datepicker__day-names {
        color: black;
    }

    .react-datepicker__day-name {
        color: black;
        font-weight: bold;
    }

    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
        background-color: #0d6efd;
        color: white;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__close-icon {
        background-color: transparent;
        color: #999;
        top: 0.5rem;
        right: 0.5rem;
    }

    .react-datepicker__current-month {
        font-weight: bold;
        color: #2678dd;
    }

    .closeDiv {
        display: inline-block;
        cursor: pointer;
        width: 30px;
        height: 43px;
        position: relative;
        top: -11px;
        text-align: center;
    }

    .closeDiv svg {
        position: relative;
        top: 3px;
    }

    .button {
        border: 0;
        cursor: pointer;
        padding: 10px;
        margin-top: 10px;
        border-radius: 3px;

    }

    .button-primary {
        color: white;
        background: rgb(38, 120, 221);
    }

    .button-primary:hover {
        background: rgb(17, 77, 154);
    }

    .noFlex {
        display: block;
    }

    .button-payment {
        border-radius: 10px;
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        background: #2678DD;
        color: white;
    }

    .button-payment:hover {
        background: #129d50;
    }

    #checkout-btn-mob {
        display: none;
    }

    #checkout-btn {
        display: block;
    }

    @media only screen and (max-width: 960px) {
        .i-title {
            width: 20% !important;
        }

        .i-traveller {
            width: 60% !important;
        }

        .i-detail {
            width: 95% !important;
        }

        .information {
            width: 100%;
            float: none;
            display: block;
        }

        .flight {
            width: 100%;
            float: none;
            display: block;
            margin-bottom: 50px;
        }
    }

`;


export default CheckoutFormArea;

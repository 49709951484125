import React, {useEffect} from 'react';
import {ThemeProvider} from "styled-components";
import Sticky from "react-stickynode";
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Navbar from 'containers/AppMinimal/Navbar';
import CheckoutForm from 'containers/AppMinimal/CheckoutForm';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from 'containers/AppMinimal/app-minimal.style';
import TestimonialSection from "../containers/SaasModern/Testimonial";
import Footer from "../containers/AppMinimal/Footer";

const Checkout = ({location}) => {
    useEffect(() => {
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Checkout"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <div id="checkout">
                    <Sticky top={0} innerZ={9999} className="sticky-nav-checkout" activeClass="sticky-nav-active">
                        <div className="checkout-clouds"></div>
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    </div>
                    <ContentWrapper>
                        <CheckoutForm state={location.state}/>
                        <TestimonialSection/>
                        <div style={{height:"40px", width:"100%" }}></div>
                    </ContentWrapper>

                    <Footer/>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Checkout;
